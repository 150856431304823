<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>


export default {
  name: 'App',
};
</script>

<style>
#app {
  background-color: #0F1C2E;
}
</style>
